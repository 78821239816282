
// @ts-nocheck - disable typescript due to to many type errors

// Packages
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState } from 'pinia';
import { PortalTarget } from 'portal-vue';
import debounce from 'lodash/debounce';

// Helpers
import { smoothScroll } from '@white-label-helper/scroll';
import { alphaNumSpace, email, notNumber, required, requiredIf, sameAs } from '@white-label-helper/vuelidate';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { trackUserIdentity } from '@white-label-helper/ga-tracking';

// Constants
import { PAYMENT_PROVIDERS, TEXT_INPUT_DEBOUNCE_TIME, VEHICLE_OCCUPANTS } from '@white-label-configuration/constants';

// Mixins
import checkoutForm from '@white-label-helper/mixin-checkout-form';
import auth from '@white-label-helper/mixin-auth';

// Store
import {
  dispatchRemoveDiscount,
  dispatchValidateDiscountCode,
  readDiscountObject
} from '@white-label-store/discount-code';

import { useMarketingPreferencesStore } from '@white-label-store/marketing-preferences';

import { commitClearFormData, commitStoreFormData, readPaymentFormData } from '@white-label-store/payment-form';

import { readCartItemsType } from '@white-label-store/cart-checkout';

import { useUserInfoStore } from '@white-label-store/user-info';

// Components
import IconMessage from '../icon-message/icon-message.vue';
import TextField from '../text-field/text-field.vue';
import Button from '../button/button.vue';
import PaymentPolicies from '../payment-policies/payment-policies.vue';
import DiscountCode from '../discount-code/discount-code.vue';
import PaymentGatewayBraintree from '../payment-gateway-braintree/payment-gateway-braintree.vue';
import PaymentGatewayStripe from '../payment-gateway-stripe/payment-gateway-stripe.vue';
import PaymentGatewayWindcave from '../payment-gateway-windcave/payment-gateway-windcave.vue';
import PaymentMarketingChannels from '../payment-marketing-channels/payment-marketing-channels.vue';
import CancellationAndAmendmentPolicy from '../cancellation-and-amendment-policy/cancellation-and-amendment-policy.vue';
import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';

// Types
import type VueI18n from 'vue-i18n';
import type { CancellationPolicy, AmendmentPolicy } from '@white-label-types/parking-booking';
import type {
  BraintreeConfig,
  CartItem,
  ParsedDiscount,
  Discount,
  StripeConfig,
  Total,
  CancellationProtection,
  WindcaveConfig
} from '@white-label-types/parking-checkout';
import type { UserData, MetaData } from '@white-label-types/stores';
import Checkbox from '../checkbox/checkbox.vue';
import PhoneField from '../phone-field/phone-field.vue';

import { datadogRum } from '@datadog/browser-rum';

export default defineComponent({
  components: {
    ButtonControl: Button,
    Policies: PaymentPolicies,
    Checkbox: Checkbox,
    PaymentGatewaysSharedBraintree: PaymentGatewayBraintree,
    PaymentGatewaysSharedStripe: PaymentGatewayStripe,
    PaymentGatewaysSharedWindcave: PaymentGatewayWindcave,
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconLockStar: () => import('@white-label-icon/icon-lock-star'),
    IconMessage,
    DiscountCode: DiscountCode,
    TextField,
    PhoneField,
    MarketingChannels: PaymentMarketingChannels,
    PortalTarget,
    CancellationAndAmendmentPolicy,
    DropdownWrapper,
  },

  mixins: [checkoutForm, auth],

  props: {
    title: {
      type: String,
      default: ''
    },
    cancellationPolicies: {
      type: Array as PropType<CancellationPolicy[] | CartItem['inventory_option']['cancellation_policies'][]>,
      default: () => []
    },
    amendmentPolicies: {
      type: Array as PropType<AmendmentPolicy[] | CartItem['inventory_option']['amendment_policies'][]>,
      default: () => []
    },
    paymentsConfig: {
      type: Object as PropType<BraintreeConfig | StripeConfig | WindcaveConfig>,
      required: true,
      default: () => ({
        token: '',
        apiKey: ''
      })
    },
    paymentsConfigReceived: {
      type: Boolean,
      required: true,
      default: false
    },
    paymentIntentUpdated: {
      type: Boolean,
      default: false
    },
    orderTotal: {
      type: Number,
      default: 0
    },
    cartToken: {
      type: String,
      default: '',
    },
    isProtected: {
      type: Boolean,
      required: true,
    },
    cancellationProtection: {
      type: Array as PropType<CancellationProtection[]>,
      required: true,
    },
    isBookingFeeCharged: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  validations() {
    const userDetailsValidations = {
      first_name: {
        required,
        notNumber
      },
      last_name: {
        required,
        notNumber
      },
      license_plate: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('plate_number');
        }),
        alphaNumSpace(val: string) {
          // Fix when an empty field is considered an error
          // We have a separate validation to check if this field is required
          if (val) {
            return alphaNumSpace(val);
          }
          return true;
        }
      },
      number_of_people_in_vehicle: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('number_of_people_in_vehicle') && this.useNewVehicleLayout;
        })
      },
      number_of_child_seats: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('is_child_seat_required') && this.useNewVehicleLayout;
        })
      },
      email: {
        required,
        email
      },
      phone: {
        phone_number: {
          required: requiredIf(() => {
            // @ts-ignore - `this` is not recognized in its context
            if (!this.checkoutForm_checkFieldVisibility('phone_number')) return false;
            // @ts-ignore - `this` is not recognized in its context
            return this.checkoutForm_checkIfFieldRequired('phone_number') || this.userDetails.marketing_channels?.sms;
          }),

          isValid: () : boolean => {
            const isEmpty = !this.userDetails.phone?.phone_number?.length;
            return !isEmpty ? this.phoneValid : true;
          }
        }
      },
      zipcode: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired('zipcode');
        })
      },
      outbound_flight: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired(
            'outbound_flight_number'
          );
        })
      },
      inbound_flight: {
        required: requiredIf(function checkIfRequired() {
          // @ts-ignore - `this` is not recognized in its context
          return this.checkoutForm_checkIfFieldRequired(
            'inbound_flight_number'
          );
        })
      },
      ...(isBookingPortal
        ? {
          others_email: {
            email
          },
          booker_email: {
            // @ts-ignore - `this` is not recognized in its context
            required: requiredIf(() => isBookingPortal),
            email
          }
        }
        : {})
    };
    const validations = {
      userDetails: {
        ...userDetailsValidations
      },
      emailConfirmation: {
        noPaste(): boolean {
          // @ts-ignore - `this` is not recognized in its context
          return !this.attemptToPaste;
        },
        sameAs: sameAs(function sameAsEmail() {
          // @ts-ignore - `this` is not recognized in its context
          return this.userDetails.email;
        }),
        required
      }
    };
    return validations;
  },

  data() {
    return {
      disableButton: false,
      userDetails: {
        first_name: '',
        last_name: '',
        email: '',
        phone: {
          phone_number: '',
          formatted_number: '',
          country_code: '',
          country_iso_code: ''
        },
        license_plate: '',
        number_of_people_in_vehicle: null,
        number_of_child_seats: null,
        zipcode: '',
        outbound_flight: '',
        inbound_flight: '',
        marketing_channels: {},
        booker_email: '',
        others_email: '',
        send_booker_email: false,
        send_customer_email: false,
        send_others_email: false,
      } as UserData,
      metaData: {
        cost_centre: ''
      } as MetaData,
      emailConfirmation: '',
      attemptToPaste: false,
      phoneValid: false,
      showDiscountCode: false,
      validatingDiscount: false,
      discountError: '',
      onEmailChangeDebounced: null as (() => void) | null,
      showMarketingPreferences: false,
      isBookingPortal,
      booker_email: '',
      others_email: '',
      isNewUser: false,
      showDropdownExpanded: false,
      numberInVehicleOpened: false,
      numberChildSeatsOpened: false,
    };
  },

  computed: {
    ...mapState(useUserInfoStore, {
      getAllUserDetails: 'readUserDetails',
    }),

    ...mapState(useMarketingPreferencesStore, {
      readMarketingPreferences: 'readMarketingPreferences',
      readUserMarketingSubscription: 'readUserMarketingSubscription',
      readMarketingStrategy: 'readMarketingStrategy',
      readCustomerUUID: 'readCustomerUUID',
    }),

    discountCode(): Discount | undefined {
      const discount = readDiscountObject(this.$store);
      return discount ? discount as Discount : undefined;
    },

    emailError(): any {
      // @ts-ignore
      if (
        !this.$v.emailConfirmation.sameAs &&
        !this.$v.userDetails.email.$error
      ) {
        // @ts-ignore
        return this.$v.emailConfirmation;
      }
      // @ts-ignore
      return this.$v.userDetails.email;
    },


    displayEmailErrorMessage(): boolean {
      // @ts-ignore
      return (
        this.$v.userDetails.email.$error && this.$v.emailConfirmation.noPaste
      );
    },

    displayBraintreeGateway(): boolean {
      return (
        process.env['NUXT_ENV_PAYMENT_PROVIDER'] === PAYMENT_PROVIDERS.BRAINTREE &&
        this.paymentsConfigReceived &&
        this.paymentNeeded
      );
    },

    displayStripeGateway(): boolean {
      return (
        process.env['NUXT_ENV_PAYMENT_PROVIDER'] === PAYMENT_PROVIDERS.STRIPE &&
        this.paymentsConfigReceived &&
        this.paymentNeeded
      );
    },

    displayWindcaveGateway(): boolean {
      const isWindcavePayment = this.$launchDarkly.variation('Enable_Windcave_Setup') &&
        process.env['NUXT_ENV_PAYMENT_PROVIDER'] === PAYMENT_PROVIDERS.WINDCAVE;
      return isWindcavePayment && this.paymentsConfigReceived && this.paymentNeeded;
    },

    displayInfoMessage(): boolean {
      return (
        // @ts-ignore - Mixin method
        this.checkoutForm_paymentMethod ===
        this.checkoutForm_PAYMENT_METHODS.BRAINTREE.PAYPAL &&
        this.paymentNeeded
      );
    },

    iconArrowClass(): object {
      return { 'transform rotate-180': this.showDiscountCode };
    },

    submitButtonLabel(): VueI18n.TranslateResult {
      if (!this.paymentNeeded) {
        return this.$t('UI.button.payForBooking');
      }

      // @ts-ignore - Mixin method
      if (
        this.checkoutForm_paymentMethod ===
        this.checkoutForm_PAYMENT_METHODS.BRAINTREE.PAYPAL
      ) {
        return this.$t('UI.button.paypal');
      }

      if (isBookingPortal) {
        return this.$t('UI.button.makeBooking');
      }
      return this.$t('UI.button.payForBooking');
    },

    submitButtonId(): string {
      // @ts-ignore - Mixin method
      if (
        this.checkoutForm_paymentMethod ===
        this.checkoutForm_PAYMENT_METHODS.BRAINTREE.PAYPAL
      ) {
        return 'GAPaymentSubmitPaypal';
      }
      return 'GAPaymentSubmitCard';
    },

    submitButtonDisabled(): boolean {
      // @ts-ignore
      return this.$v.$error || this.disableButton;
    },

    paymentNeeded(): boolean {
      return this.orderTotal !== 0;
    },

    isDiscountFieldEnabled(): boolean {
      return getAppVariable(
        'discount_visibility_rules.is_enabled_in_payment_page'
      );
    },

    userMarketingSubscription() {
      return this.readUserMarketingSubscription;
    },

    marketingStrategy() {
      return this.readMarketingStrategy;
    },

    partnerMarketingPreferences() {
      return this.readMarketingPreferences;
    },

    cartItemsType(): ReturnType<typeof readCartItemsType> {
      return readCartItemsType(this.$store);
    },
    isBookerEmailCheckboxDisabled() {
      return !(this.userDetails.booker_email && this.userDetails.booker_email.length)
    },
    isCustomerEmailCheckboxDisabled() {
      return !(this.userDetails.email && this.userDetails.email.length)
    },
    isOthersEmailCheckboxDisabled() {
      return !(this.userDetails.others_email && this.userDetails.others_email.length)
    },

    /**
     * Returns the email data from Vuex store userInfo
     */
    getUserInfoEmail() {
      return this.getAllUserDetails.email;
    },

    /**
     * Returns the first name data from Vuex store userInfo
     */
    getUserInfoFirstName() {
      return this.getAllUserDetails.firstName;
    },

    /**
     * Returns the last name data from Vuex store userInfo
     */
    getUserInfoLastName() {
      return this.getAllUserDetails.lastName;
    },

    /**
     * Returns the postal code data from Vuex store userInfo
     */
    getUserPostalCode() {
      return this.getAllUserDetails.postalCode;
    },

    /**
     * Returns the license plate data from Vuex store userInfo
     */
    getUserInfoLicense() {
      return this.getAllUserDetails.vehicleDetails.registrationNumber;
    },

    /**
     * Returns the phone number data from Vuex store userInfo
     */
    getPhoneNumbers() {
      return this.getAllUserDetails.phoneNumbers;
    },

    displayVehicleDetails(): boolean {
      return this.checkoutForm_checkFieldVisibility('plate_number') || this.checkoutForm_checkFieldVisibility('number_of_people_in_vehicle');
    },

    numberInVehicleOptions(): { id: number | string, name: string }[] {
      const options = [...VEHICLE_OCCUPANTS];
      // Number in vehicle can't be 0
      return options.slice(1);
    },

    numberChildSeatsOptions(): { id: number | string, name: string }[] {
      const options = [...VEHICLE_OCCUPANTS];
      // Number of child seats can't be more than (number in vehicle -1)
      if (this.userDetails.number_of_people_in_vehicle) {
        return options.slice(0, this.userDetails.number_of_people_in_vehicle);
      }
      return [];
    },

    computedNumberInVehicle: {
      get(): {id: number | string, name: string} {
        return (
          this.numberInVehicleOptions.find(
            (item) => item.name === this.userDetails.number_of_people_in_vehicle?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: {id: number, name: string}): void {
        this.userDetails.number_of_people_in_vehicle = parseInt(val.name);
        if (this.userDetails.number_of_people_in_vehicle === 1) {
          this.userDetails.number_of_child_seats = 0;
        } else {
          this.userDetails.number_of_child_seats = null;
        }
      }
    },

    computedNumberChildSeats: {
      get(): {id: number | string, name: string} {
        return (
          this.numberChildSeatsOptions.find(
            (item) => item.name === this.userDetails.number_of_child_seats?.toString()
          ) || {
            name: '',
            id: '',
          }
        );
      },
      set(val: {id: number, name: string}): void {
        this.userDetails.number_of_child_seats = parseInt(val.name);
      }
    },

    disableChildSeatsDropdown(): boolean {
      return !this.userDetails.number_of_people_in_vehicle;
    },

    numberInVehicleError(): boolean {
      // @ts-ignore
      return this.$v.userDetails.number_of_people_in_vehicle.$error;
    },

    numberChildSeatsError(): boolean {
      // @ts-ignore
      return this.$v.userDetails.number_of_child_seats.$error;
    },

    useNewVehicleLayout(): boolean {
      return this.$launchDarkly.variation('ECOM-2380_VEHICLE_DETAILS') === true;
    },

    checkoutFormCroExperimentActive(): boolean {
      return this.$launchDarkly.variation('ECOM_2230_CHECKOUT_FORM_CRO_EXPERIMENT') === true;
    },
  },

  watch: {
    userDetails: {
      deep: true, // watch nested data properties
      handler(formData: UserData) {
        commitStoreFormData(this.$store, { formData });

        // Uncheck recipients checkbox if email value is empty
        if(!formData.email) {
          this.userDetails.send_customer_email=false
        }
        if(!formData.others_email) {
          this.userDetails.send_others_email=false
        }
      },
    },
  },

  async mounted() {
    if (this.$loggedUser) {
      // If user is logged in retrieve data from userInfo store to populate fields
      await this.getUserData();
    } else {
      this.loadStoredData();
    }

    this.onEmailChangeDebounced = debounce(
      this.handleEmailChange,
      TEXT_INPUT_DEBOUNCE_TIME
    );

    if (isBookingPortal) {
      this.handleEmailBookerChange();
    }
  },

  methods: {
    ...mapActions(useUserInfoStore, {
      fetchUserDetails: 'fetchUserDetails',
    }),

    ...mapActions(useMarketingPreferencesStore, {
      dispatchUserMarketingSubscription: 'dispatchUserMarketingSubscription',
    }),

    onFormFieldBlur(field: string): void {
      if (field.startsWith('email') && this.onEmailChangeDebounced) {
        this.onEmailChangeDebounced();
      }

      if (field === 'emailConfirmation') {
        this.$v[field].$touch();
      } else {
        this.$v.userDetails[field].$touch();
      }
    },
    onPaste(): void {
      this.attemptToPaste = true;
      // @ts-ignore
      this.$v.emailConfirmation.$touch();
      setTimeout(() => {
        this.attemptToPaste = false;
        // @ts-ignore
        if (!this.$v.$error) {
          // @ts-ignore
          this.$v.emailConfirmation.$reset();
        }
      }, 3000);
    },

    handleUserMarketingChannelsChange(marketingChannels: UserData['marketing_channels']): void {
      this.userDetails.marketing_channels = marketingChannels;
    },

    handleEmailBookerChange(): void {
      const userDetails = this.$auth;
      this.userDetails.booker_email = userDetails.$state.user.email;
    },

    handleEmailChange() {
      // @ts-ignore - no access to $v
      if (
        !this.$v.userDetails.email.$invalid &&
        this.userDetails.email === this.emailConfirmation
      ) {
        let customerUUID: string | undefined;

        this.dispatchUserMarketingSubscription(this.userDetails.email)
          .then((data) => {
            // Check if new user
            this.isNewUser = (data === null);

            if (this.userDetails.marketing_channels) {
              this.updateDefaultMarketingChannelsUserValues();

              /**
               * Toggles the expansion state of the marketing pref dropdown
               * based on the availability of the phone number field and user preferences.
               */
              if (!this.isNewUser) {
                const { sms, email } = this.userDetails.marketing_channels;
                const phoneNumberFieldVisible = this.checkoutForm_checkFieldVisibility('phone_number');
                this.showDropdownExpanded = phoneNumberFieldVisible ? !(sms && email) : !email;

                customerUUID = this.readCustomerUUID;
              }
            }

            this.showMarketingPreferences = true;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            trackUserIdentity(this.$gtm, this.userDetails.email, customerUUID);
          })
      }
    },
    /**
     * Set default marketing preferences according to
     * opt-in/opt-out startegy for new user and already saved settings
     * for an old user
     */
    updateDefaultMarketingChannelsUserValues() {
      const marketingChannels: UserData['marketing_channels'] = {};
      const optIn = this.marketingStrategy === "opt-in";
      const savedUserMarketingChannels = this.userMarketingSubscription?.marketing_channels;

      if (this.isMarketingChannelAvailable('email')) {
        marketingChannels.email = this.isNewUser ?
          !optIn :
          !!savedUserMarketingChannels?.email;
      }

      if (this.isMarketingChannelAvailable('sms')) {
        marketingChannels.sms = this.isNewUser ?
          !optIn :
          !!savedUserMarketingChannels?.sms;
      }

      this.userDetails = {
        ...this.userDetails,
        marketing_channels: marketingChannels
      };
    },

    isMarketingChannelAvailable(channel: string): boolean {
      return !!this.partnerMarketingPreferences?.marketing_channels?.includes(
        channel
      );
    },

    /*
    * Samuel Bruton
    * Confirming with another dev on what this actually is being used for.
    * Looks like it can be removed.
    * */
    loadStoredData(): void {
      if (!this.checkoutFormCroExperimentActive) return;
      const formData = readPaymentFormData(this.$store);
      if (formData) {
        this.userDetails = {
          ...formData,
          // @ts-ignore mixin method
          phone: this.checkoutForm_checkFieldVisibility('phone_number')
            ? { ...formData.phone }
            : { ...this.userDetails.phone },
          // @ts-ignore mixin method
          license_plate: this.checkoutForm_checkFieldVisibility('plate_number')
            ? formData.license_plate
            : '',
          // @ts-ignore mixin method
          zipcode: this.checkoutForm_checkFieldVisibility('zipcode')
            ? formData.zipcode
            : '',
          // @ts-ignore mixin method
          outbound_flight: this.checkoutForm_checkFieldVisibility('outbound_flight_number')
            ? formData.outbound_flight
            : '',
          // @ts-ignore mixin method
          inbound_flight: this.checkoutForm_checkFieldVisibility('inbound_flight_number')
            ? formData.inbound_flight
            : '',
          // @ts-ignore mixin method
          number_of_people_in_vehicle: this.checkoutForm_checkFieldVisibility('number_of_people_in_vehicle')
            ? formData.number_of_people_in_vehicle
            : null,
          // @ts-ignore mixin method
          number_of_child_seats: this.checkoutForm_checkFieldVisibility('is_child_seat_required')
            ? formData.number_of_child_seats
            : null
        };
      }
    },

    /** API call to get the user data */
    async getUserData(): Promise<void> {
      if (this.tokenIsValid) {
        const token = this.getToken;
        await this.fetchUserDetails(token)
          .then(() => {
            const formData = this.checkoutFormCroExperimentActive ? readPaymentFormData(this.$store) ?? {} as UserData : {} as UserData;

            const getDataFromUserOrStore = (field: string, loggedInUserData: string) => {
              return formData[field] ? formData[field] : loggedInUserData;
            }

            this.userDetails = {
              ...formData,
              email: getDataFromUserOrStore('email', this.getUserInfoEmail),
              first_name: getDataFromUserOrStore('first_name', this.getUserInfoFirstName),
              last_name: getDataFromUserOrStore('last_name', this.getUserInfoLastName),
              // @ts-ignore mixin method
              license_plate: this.checkoutForm_checkFieldVisibility('plate_number')
                ? getDataFromUserOrStore('plate_namber', this.getUserInfoLicense)
                : '',
              // @ts-ignore mixin method
              zipcode: this.checkoutForm_checkFieldVisibility('zipcode')
                ? getDataFromUserOrStore('zipcode', this.getUserPostalCode)
                : '',
              // @ts-ignore mixin method
              phone: this.checkoutForm_checkFieldVisibility('phone_number')
                ? formData?.phone?.phone_number
                  ? { ...formData.phone }
                  : {
                      phone_number: this.getPhoneNumbers.phoneNumber,
                      formatted_number: this.getPhoneNumbers.phoneNumberWithDialingCode,
                      country_code: this.getPhoneNumbers.dialingCode,
                      country_iso_code: this.getPhoneNumbers.isoCode,
                    }
                : {
                  // empty data needed otherwise api call will fail
                    phone_number: '',
                    formatted_number: '',
                    country_code: '',
                    country_iso_code: '',
                },
              // @ts-ignore mixin method
              outbound_flight: this.checkoutForm_checkFieldVisibility('outbound_flight_number') && formData?.outbound_flight
                ? formData.outbound_flight
                : '',
              // @ts-ignore mixin method
              inbound_flight: this.checkoutForm_checkFieldVisibility('inbound_flight_number') && formData?.inbound_flight
                ? formData.inbound_flight
                : '',
              // @ts-ignore mixin method
              number_of_people_in_vehicle: this.checkoutForm_checkFieldVisibility('number_of_people_in_vehicle')  && formData?.number_of_people_in_vehicle
                ? formData.number_of_people_in_vehicle
                : null,
              // @ts-ignore mixin method
              number_of_child_seats: this.checkoutForm_checkFieldVisibility('is_child_seat_required') && formData?.number_of_child_seats
                ? formData.number_of_child_seats
                : null,
              marketing_channels: this.partnerMarketingPreferences
            };
          })
          .catch((error: Error) => {
            console.error('Error with fetchUserDetails. Error logged with Datadog.')
            datadogRum.addError(error);
          })
      }
    },

    validateForm(): void {
      // @ts-ignore
      this.$v.$touch();

      // @ts-ignore
      if (this.$v.$error) {
        smoothScroll('.input-container--error');
        return;
      }

      if(!this.displayWindcaveGateway) {
        this.disableButton = true;
      }

      if (!this.paymentNeeded || isBookingPortal) {
        // @ts-ignore - Mixin method
        this.checkoutForm_handlePaymentCompleted({
          payment_method_id: 'zero',
          merchant_id: 'zero'
        });
      } else {
        // @ts-ignore
        this.$refs.paymentGateway.submitForm();
      }
    },

    addDiscount(val: string): void {
      this.validatingDiscount = true;
      this.disableButton = true;

      const params = {
        cartToken: this.cartToken,
        code: val.replace(/[/\\]/g, '|')
      };

      dispatchValidateDiscountCode(this.$store, params)
        .then(({ orderTotals, items }: ParsedDiscount) => {
          this.$emit('updateItems', items);
          this.$emit('updateOrderTotals', orderTotals);
          this.handleWindcaveReload();
          this.discountError = '';

          if (!this.displayWindcaveGateway) {
            this.disableButton = false;
          }
        })
        .catch((e: Error) => {
          this.$emit('handleAddDiscountError');
          const errorResponse = JSON.parse(e.message);
          this.discountError = errorResponse.body.message;
          this.disableButton = false;
        })
        .finally(() => {
          this.validatingDiscount = false;
        });
    },

    removeDiscount(val: string): void {
      this.validatingDiscount = true;
      this.disableButton = true;

      const params = {
        cartToken: this.cartToken,
        code: val
      };

      dispatchRemoveDiscount(this.$store, params)
        .then(
          ({ orderTotals, items }: { orderTotals: Total; items: CartItem }) => {
            this.$emit('updateItems', items);
            this.$emit('updateOrderTotals', orderTotals);
            this.handleWindcaveReload();

            this.discountError = '';
          }
        )
        .catch((e: Error) => {
          const errorResponse = JSON.parse(e.message);
          this.discountError = errorResponse.body.message;
        })
        .finally(() => {
          this.validatingDiscount = false;

          if (!this.displayWindcaveGateway) {
            this.disableButton = false;
          }
        });
    },

    errorHandler(): void {
      this.disableButton = false;
      this.$emit('error');
    },

    /**
     * For Windcave, whenever a discount is added or removed,
     * it's mandatory to send a new client-config request with the updated price.
     * This ensures that a new submitCardLink is received with reloading the payment widget
     */
    handleWindcaveReload(): void {
      if (this.displayWindcaveGateway) {
        this.$emit('reloadWindcave');
      }
    },

    addCancellationProtection(): void {
      this.$emit('add-cancellation-protection');

      if (this.displayWindcaveGateway) {
        this.disableButton = true;
      }
    },

    removeCancellationProtection(): void {
      this.$emit('remove-cancellation-protection');

      if (this.displayWindcaveGateway) {
        this.disableButton = true;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkSuccess(validation: any, value: string | number | null, isRequired: boolean): boolean {
      if (!this.checkoutFormCroExperimentActive) return false;
      if (isRequired) return validation.$anyDirty && !validation.$anyError;
      /**
      * For fields with only requiredIf=false validation (ex. zipcode) - validation.$anyError will always be false.
      * Extra check for the field value is required to prevent displaying the success state for an empty field.
      */
      return validation.$anyDirty && !validation.$anyError && !!value;
    }
  }
});
