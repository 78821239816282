
import { defineComponent, PropType } from 'vue';
import { WindcaveConfig } from '@white-label-types/parking-checkout';

export default defineComponent({
  props: {
    paymentsConfig: {
      type: Object as PropType<WindcaveConfig>,
      required: true,
    },
  },

  data: () => ({
    loadWindcave: true,
    showError: false,
  }),

  mounted(): void {
    this.$global_eventEmitter.$on('windcaveHandleReload', this.handleWindcaveError);
  },

  beforeDestroy (): void {
    this.$global_eventEmitter.$off('windcaveHandleReload', this.handleWindcaveError);
  },

  methods: {
    handleWindcaveTransaction() {
      this.$emit('payment-completed', {
        payment_method_id: this.paymentsConfig.sessionId,
        merchant_id: process.env.NUXT_ENV_PAYMENT_MERCHANT_ID,
      });
    },

    /**
     * Handles the Windcave payment error event by showing the error message
     * and reinitializing the Windcave payment form, allowing the user to retry the payment.
     *
     */
    handleWindcaveError(shouldShowEror: false) {
      if (shouldShowEror) {
        this.showError = true;
      }

      this.$emit('windcaveWidgetStartedLoading');
      this.$refs.payment?.createWindcavePaymentForm();
    },

    /**
     * Triggers the submit button inside 'windcave-ui' custom element.
     * This button is hidden with data-basic-styling property.
     */
    submitForm() {
      const btn: HTMLButtonElement | null = document.querySelector('.windcave-dropin-card-submit-button');
      btn?.click();
    },
  },
})
